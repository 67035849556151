<template>
  <v-dialog :value="value" @input="$event => $emit($event)" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Criar novo Chip</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field label="ICCID*" disabled dense outlined :value="iccid" />
            </v-col>

            <v-col cols="12">
              <v-autocomplete :items="opcoes.estoque" label="Estoque*" v-model="form.estoque" dense outlined
                :disabled="isLoading" :loading="isLoading" item-text="descricao" item-value="id_estoque" />
            </v-col>

            <v-col cols="12">
              <v-autocomplete :items="opcoes.operadora" label="Operadora*" v-model="form.operadora" dense outlined
                :disabled="isLoading" :loading="isLoading" item-text="nome_operadora" item-value="id_operadora" />
            </v-col>

            <v-col cols="12" md="3">
              <v-switch style="margin-top: 0px;" v-model="form.hibrido" label="Híbrido" :disabled="isLoading" />
            </v-col>

            <v-col cols="12" md="9" v-show="!form.hibrido">
              <v-autocomplete :items="opcoes.ddd" label="DDD*" v-model="form.ddd" dense outlined
                :disabled="isLoading || form.hibrido" :loading="isLoading" item-value="[]" :filter="filterDDDs">
                <template #item="data">
                  {{ data.item.descricao }} - {{ data.item.ddd }}
                </template>
                <template #selection="data">
                  {{ data.item.descricao }} - {{ data.item.ddd }}
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-autocomplete :items="opcoes.situacao" item-text="label" label="Situação*" disabled :value="form.situacao"
                dense outlined />
            </v-col>
          </v-row>
        </v-container>

        <small>* Campos obrigatórios</small>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="blue darken-1" :disabled="meta.sending" text @click="cancel()">
          Fechar
        </v-btn>

        <v-btn color="blue darken-1" :disabled="isLoading" text @click="save()">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@/axios.config";

const Situacao = Object.freeze({
  Estoque: 0,
  Ativo: 1,
  Inativo: 2
});

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    iccid: {
      type: String,
      default: undefined
    },
    listIccid: {
      type: Array,
      default: () => [],
    },
    index: {
      type: String,
      default: undefined
    }
  },

  async mounted() {
  },

  data: function () {
    return {
      form: {
        estoque: undefined,
        hibrido: false,
        operadora: undefined,
        situacao: Situacao.Estoque,
        ddd: undefined,
        id_ddd: undefined,
        iccid: undefined
      },
      meta: {
        loading: true,
        sending: false,
      },
      opcoes: {
        operadora: [],
        estoque: [],
        ddd: [],
        situacao: [],
      },
      errors: []
    }
  },

  methods: {
    close: function (...args) {
      /* Não permite fechar a modal enquanto requisão está sendo feita */
      if (this.meta.sending) {
        return;
      }

      if (this.index !== undefined) {
        this.$emit('close', { ...args, index: this.index })
      } else {
        this.$emit('close', ...args)
      }

    },

    cancel: function () {
      this.close({ cancelled: true });
    },

    checkForm: function () {
      this.errors = [];
      if (!this.form.estoque) {
        this.errors.push('Estoque');
      }
      if (!this.form.operadora) {
        this.errors.push(' Operadora');
      }
      if (!this.form.hibrido && !this.form.ddd) {
        this.errors.push(' DDD');
      }
      return this.errors.length;
    },

    save: async function () {
      this.meta.sending = true;
      const id_ddd = this.form.ddd ? this.form.ddd.id_ddd : null;
      this.form.iccid = this.iccid;
      this.form.id_ddd = id_ddd;
      const checkForm = this.checkForm();

      if (checkForm > 0) {
        await this.$swal("Oops...", "Favor preencha o(s) campo(s): " + this.errors.join() + ".", "warning");
        this.meta.sending = false;
        return;
      }

      const { data } = await axios.post("/solicitation/create-new-chip", this.form);
      if (data.success) {
        const new_chip = {
          ddd: this.form.ddd ? this.form.ddd.ddd : 'HIBRIDO',
          hibrido: this.form.hibrido,
          iccid: this.iccid,
          id_ddd: id_ddd,
          id_estoque: this.form.estoque,
          id_operadora: this.form.operadora,
          situacao: "0"
        };
        this.listIccid.push(new_chip);

        if (this.index !== undefined) {
          this.$emit('changeSelectedChip', {new_chip, index: this.index});
        } else {
          this.$emit('changeSelectedChip', new_chip);
        }

      }

      if (!data.success) {
        await this.$swal("Oops...", data.message, "error");
      }
      this.meta.sending = false;
      this.close({ cancelled: false });
    },

    filterDDDs: function (item, queryText) {
      const regexp = new RegExp(queryText, 'i');

      return regexp.test(item.descricao) || regexp.test(item.ddd)
    }
  },

  computed: {
    isLoading: function () {
      return this.meta.loading || this.meta.sending
    }
  },

  watch: {
    'form.hibrido': function (n) {
      if (n) {
        /* Se híbrido limpar campo de ddd */
        this.form.ddd = undefined;
      }
    },

    'value': function (n) {
      if (n) {
        this.opcoes.situacao = Object.entries(Situacao)
          .reduce((acc, [label, value]) => {
            acc.push({ label, value })

            return acc;
          }, []);

        Promise.all([
          axios.get('solicitation/stock'),
          axios.get('solicitation/provider'),
          axios.get('solicitation/ddd'),
        ]).then((promises) => {
          const [estoques, operadoras, ddds] = promises

          if (estoques.data.success) {
            this.opcoes.estoque = estoques.data.data;
          }

          if (operadoras.data.success) {
            this.opcoes.operadora = operadoras.data.data;
          }

          if (ddds.data.success) {
            this.opcoes.ddd = ddds.data.data;
          }

          this.meta.loading = false
        }).catch(() => { this.meta.loading = false });
      }
    }
  }
}
</script>

<style scoped></style>