<template>
  <div>
    <v-autocomplete
      v-model="personSelected"
      :items="personList"
      outlined
      dense
      return-object
      clearable
      item-text="nome_completo"
      item-value="id"
      :label="label"
      :rules="rules"
      :loading="loading"
      :filter="filterObject"
      :readonly="readonly"
      @change="save"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    selectedPerson: {
      type: [Object, Array],
      default: () => {},
    },
    label: {
      type: String,
      default: 'Pessoa'
    },
    rules: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      personSelected: {},
      personList: [],
      loading: true,
    };
  },
  async mounted() {
    try {
      if (this.selectedPerson && Object.keys(this.selectedPerson).length > 0) {
        this.personSelected = this.selectedPerson;
        if (this.readonly) {
          this.personList = [this.personSelected];
        }
      }
      if (!this.readonly) {
        await this.loadPersons();
        this.personList = this.persons.data;
      }
      this.loading = false;
    } catch (e) {
      await this.$swal("Oops...", e.message, "error");
    }
  },
  computed: {
    ...mapState("persons", ["persons"]),
  },
  methods: {
    ...mapActions("persons", ["loadPersons"]),

    save (value) {
      if (!this.readonly) {
        if (!value) value = {};
        this.$emit("save", value);
      }
    },

    filterObject(item, queryText) {
      const nome_abreviado = item.nome_abreviado.toLowerCase();
      const nome_completo = item.nome_completo.toLowerCase();
      const nome_original = item.nome_original.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (nome_abreviado.indexOf(searchText) > -1 || nome_completo.indexOf(searchText) > -1 || nome_original.indexOf(searchText) > -1);
    },
  },
  watch: {
    selectedPerson(newVal) {
      if (this.personSelected !== newVal) {
        this.personSelected = newVal;
      }
    }
  },
};
</script>
<style scoped></style>
