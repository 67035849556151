<template>
  <div>
    <v-combobox
      label="Buscar ou criar novo ICCID"
      v-model="selectedChip"
      item-text="iccid"
      item-value="iccid"
      :items="iccids"
      outlined
      dense
      :search-input.sync="searchInputs"
      :loading="loading"
      return-object
      :readonly="readOnlyField"
      @change="saveSelectedChip"
      :filter="filterIccid"
      :rules="rules"
      @keydown="onKeyDown($event)">
      <template #no-data>
        <v-list-item v-if="searchInputs != null && searchInputs.length > 0" @click="createNewIccid(searchInputs)">
          <span class="subheading mr-2">Criar ICCID</span>{{ searchInputs }}
        </v-list-item>
      </template>

      <template #selection="data">
        <template v-if="selectedChip != null && Object.keys(selectedChip).length > 0 && data.item.ddd && data.item.iccid">
          (DDD - {{ data.item.ddd }}) {{ data.item.iccid }}
        </template>
        <template v-if="selectedChip != null && Object.keys(selectedChip).length > 0 && !data.item.ddd && data.item.iccid">
          (HIBRIDO) {{ data.item.iccid }}
        </template>
      </template>

      <template #item="data">
        <v-list-item-content v-if="data.item.ddd">
          <v-list-item-title>(DDD - {{ data.item.ddd }}) {{ data.item.iccid }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="!data.item.ddd">
          <v-list-item-title>(HIBRIDO) {{ data.item.iccid }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-combobox>

    <custom-new-single-chip-form-component
      v-model="isChipDialogOpen"
      v-if="!readOnlyField"
      :iccid="createIccid"
      :listIccid="iccids"
      @changeSelectedChip="updateSelectedChip($event)"
      @close="onCloseDialog($event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "@/axios.config";
import CustomNewSingleChipFormComponent from "@/components/forms/CustomChangeChipComponent/CustomNewSingleChipFormComponent.vue";

export default {
  components: { CustomNewSingleChipFormComponent },
  props: {
    ddd: {
      type: String,
      default: '',
    },
    chipSelected: {
      type: [Object, Array],
      default: () => {},
    },
    readOnlyField: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectedChip: [],
      iccids: [],
      searchInputs: '',
      isChipDialogOpen: false,
      createIccid: '',
    };
  },
  async mounted() {
    if (this.ddd !== '') {
      await this.fetchChips(this.ddd);
    }
    if (this.chipSelected && Object.keys(this.chipSelected).length > 0) {
      this.selectedChip = this.chipSelected;
      if (this.readOnlyField) {
        this.iccids = [this.selectedChip];
      }
    }
  },
  watch: {
    async ddd(newVal) {
      if (newVal !== '' && !this.readOnlyField) {
        this.loading = true;
        await this.fetchChips(newVal);
        this.selectedChip = {};
        this.saveSelectedChip({});
        this.loading = false;
      }
    },
    chipSelected(newVal) {
      if (newVal !== '' && !this.readOnlyField) {
        this.selectedChip = newVal;
      }
    }
  },
  methods: {
    onCloseDialog: function () {
      this.isChipDialogOpen = false;
      this.createIccid = undefined
      this.searchInputs = undefined
    },

    updateSelectedChip: function (event) {
      if (!event.iccid) {
        this.$swal(
          "Oops..", "Ocorreu um erro ao salvar novo ICCID",
          "error"
        );
        return;
      }
      this.selectedChip = event;
      this.saveSelectedChip(event);
    },

    createNewIccid: function (iccid) {
      this.isChipDialogOpen = true;
      this.createIccid = iccid;
    },

    onKeyDown: function (e) {
      /**
       * Workaround: Quando enter é pressionado componente combobox
       * joga valor do search para um campo mágico e acaba com todo o fluxo.
       * Dessa forma esvazia de forma forçada o campo de busca e povoa o campo
       * para criação de iccid
       */
      if (e.keyCode === 13 /* ENTER */) {
        this.createIccid = this.searchInputs
        this.searchInputs = undefined
      }
    },

    filterIccid: function (item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      return regExp.test(item.iccid)
    },

    saveSelectedChip: function (data) {
      if (!this.readOnlyField) {
        if (!data) data = {};
        this.$emit("changeData", data);
      }
    },

    fetchChips: async function (ddd) {
      this.loading = true
      await axios.get('solicitation/chips/available', {
        params: {ddd}
      })
        .then((res) => {

          if (res.data.success) {
            this.iccids = res.data.data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  }
};
</script>
<style scoped></style>
